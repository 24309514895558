@font-face {
  font-family: "LibreBaskerville-RegularItalic";
  src: url("assets/fonts/LibreBaskerville-RegularItalic.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Libre Baskerville";
  src: url("assets/fonts/LibreBaskerville-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "LibreBaskerville-Italic";
  src: url("assets/fonts/LibreBaskerville-Italic.ttf");
}
@font-face {
  font-family: "LibreBaskerville-Bold";
  src: url("assets/fonts/LibreBaskerville-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "LibreBaskerville-Bold";
  src: url("assets/fonts/LibreBaskerville-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("assets/fonts/Metropolis/Metropolis-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("assets/fonts/Helvetica Neue/HelveticaNeueLTStd-ThEx.otf") format("opentype");
}
