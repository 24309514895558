.career-container{
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/assets/images/electric-america.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 35rem;
    /* background-position: 50% -35%; */
    padding-top: 27rem;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .career-heading{
        width: 50%;
        margin-top: -12rem;
        padding-left: 2rem;
        h3{
            color: #ffffff;
            font-family: "Libre Baskerville";
            // font-size: 20px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 40px;
            text-align: left;
            
        }
        p{
            /* Style for "The work y" */
            color: #ffffff;
            font-family: p22-underground;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            margin-top: 1rem;
        }        
    }

    .btn{
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #569ed2;
        border:none;
        color: #ffffff;
        font-family: Metropolis;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        padding: 1rem;
        margin-top: 1rem;
        margin-left: 2rem;
    }
}
.section-two{
    background-image: url('/assets/images/Rectangle_47.png');
    background-repeat: no-repeat;
    background-position: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-size:cover;
    .section-two-wrap{
        padding-top: 2rem;
        .section-two-header{
            color: #ff7510;
            font-family: "Libre Baskerville";
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: 60px;
            text-align: center;
        }
        .section-two-text{
            margin-top: 2rem;
            color: #292929;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            /* Text style for "We are com" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        .icons-wrap{
            padding-top: 2rem;
            display: flex;
            // height:15rem;
            .icon{
                width: 82px;
                height: 59px;
            }
            h6{
                margin-top: 2rem;
                font-family: "Metropolis";
            }
        }
        .btn{
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #569ed2;
            padding-right: 1rem;
            padding-left: 1rem;
            border: none;
        }
    }
    .section-three-wrap{
        padding-top: 2rem;
        .section-three-header{
            color: #606060;
            font-family: "Libre Baskerville";
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: 60px;
            text-align: left;
        }
        .section-two-text{
            margin-top: 2rem;
            color: #292929;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            /* Text style for "We are com" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        .icons-wrap{
            padding-top: 2rem;
            display: flex;
            // height:15rem;
            .icon{
                width: 82px;
                height: 59px;
            }
            h6{
                margin-top: 3rem;
            }
        }
        .btn{
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #569ed2;
            padding-right: 1rem;
            padding-left: 1rem;
            border: none;
        }
    }
    .job-opening-row{
        border: none;
        background-color: #ffffff;
       
    }
    .jobs-table{
        background-color: #ffffff;
        th,td{
            color: #333333;
            font-family: "Libre Baskerville";
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            align-items: center;
        }
        th{
            font-weight: bold; 
        }
        .apply-now-text{
            color: #2294e3;
            cursor: pointer;
            // border-color: #333333;
        }
    }

}
.modal-wrapper{
    .modal-title{
        color: #606060;
        font-family: "Libre Baskerville";
        font-size: 20px;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
    }
    .show-modal{
        background-image: url('/assets/images/Rectangle_47.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
        // .submit-btn-wrap{
            .submit-btn{
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                background-color: #96d63f;
                border: none;
                color: #ffffff;
                font-family: Metropolis;
                font-size: 14px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                border: none;
                width: 10rem;
                :hover{
                    background-color: #96d63f !important; 
                    border: none !important;
                }
            // }
        }
        .mb-3, .resume-label{
            color: #606060;
            font-family: "Libre Baskerville";
            font-size: 14px;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
        }
        .upload-btn{
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            background-color: #569ed2;
            border: none;
        }
    }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.form-label {
    font-family: "Libre Baskerville";
    font-size: 14px;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}
.invalid-feedback {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
    font-family: "Libre Baskerville";
    font-size: 12px;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}
.form-row {
    margin-bottom: 1rem;
    input{
        margin-left: 12px;
        width: 95%;
    }
}
.required:after {
    content:"*";
    color:red;
  }
 
  .text-right{
    text-align: right;
  }
  .captcha-wrap{
      margin-bottom: 1rem;
      margin-left: -0.6rem;
      margin-top: 1rem;
    }
    .rc-anchor-light.rc-anchor-normal {
        border: none important;
    }
    .form-control:valid, .form-control:invalid{
        background-image: none !important;
        border-color: none !important;
    }
    .job-description{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .section-four-wrap{
        .apply-now-text{
            color: #606060;
            font-family: "Libre Baskerville";
            font-size: 14px;
            font-weight: bold;
            text-align: left;
            /* Text style for "Job Openin" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        h3{
            color: #333333;
            font-family: "Libre Baskerville";
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            align-items: center;
        }
        p{
            color: #333333;
            font-family: "Libre Baskerville";
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            align-items: center;
        }
        .btn{
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #569ed2;
            padding-right: 1rem;
            padding-left: 1rem;
            border: none;
        }
    }
  