  .carousel-wrap{
    margin-top: 1rem;
    margin-bottom: 2rem;
    min-height: 35rem;
    // padding-bottom: 2rem;
    .carousel-left{
        margin-top: 4.5rem;
        text-align:unset !important;
        display: flex;
        align-items: center;
        padding-bottom: 50px;
        padding-left: 50px;
        .carousel-head{
            color: #4f5252;
            font-family: p22-underground;
            font-size: 18px;
            font-weight: 400;
            text-align: left;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-transform: uppercase;
            padding-bottom: 1rem;
        }
        .carousel-desc{
            /* Style for "Fortify yo" */
            color: #0f0f0f;
            font-family: "Libre Baskerville";
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            /* Text style for "Fortify yo" */
            line-height: normal;
            padding-bottom: 2rem;
        }
        .carousel-btn{
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #569ed2;
            border: none;
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            /* Text style for "Our Servic" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            padding: 1rem;
        }
    }
    .carousel-right{
        text-align: center;
        margin-top: 1rem;
        padding-bottom: 4rem;
        position: relative;
        justify-content: flex-start;
        .img-main{
            width: 75% !important;
            left: 0;
            top: 0;
        }
        .img-sub{
            width: 25% !important;
            position: absolute !important;
            top: 34%;
            right: 0;
            left: 65%;
            border-radius: 40rem;
        }
    }
}
.carousel .control-dots {
    text-align: left !important;
    margin-left: 1.5rem !important;
    bottom: 5rem !important;
}
.carousel .control-dots .dot {
    background:  #a6a3a3 !important;
    border-radius: 50%;
    box-shadow: none !important;
    width: 12px !important;
    height: 12px !important;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    background-color: #579fd2 !important;
}
.carousel-row{
    padding-bottom: 1rem !important;
}
.carousel .slide{
    text-align: unset !important;
}
/*Media Queries*/
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  
    .carousel .control-dots {
        bottom: 0.5rem !important;
    }
    .carousel-wrap{
        // margin-top: 1rem;
        margin-bottom: 3rem !important;

        .carousel-left {
            .carousel-desc {
                font-size: 20px !important;        
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .carousel .control-dots {
        bottom: 0rem !important;
    }
    .carousel-wrap{
        // margin-top: 1rem;
        margin-bottom: 2rem !important;

        .carousel-left {
            .carousel-desc {
                font-size: 20px !important;        
            }
        }
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .carousel .control-dots {
        bottom: 2rem !important;
    }
    .carousel-wrap{
        margin-bottom: 2rem !important;

        .carousel-left {
            .carousel-desc {
                font-size: 20px !important;        
            }
        }
    }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .carousel .control-dots {
        bottom: 5rem !important;
    }
    .carousel-wrap{
        bottom: -3rem !important;

        .carousel-left {
            .carousel-desc {
                font-size: 30px !important;        
            }
        }
    }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .carousel .control-dots {
        bottom: 5rem !important;
    }
    .carousel-wrap{
        margin-bottom: 2rem !important;

        .carousel-left {
            .carousel-desc {
                font-size: 30px !important;        
            }
        }
    }
  }