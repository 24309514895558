.timeline-wrap{
    background-image: url('/assets/images/Path_45 (1).png');
    background-repeat: no-repeat;
    // background-position: bottom;
    background-position: 45% 40%;
    background-size:stretch;
    -webkit-background-size: stretch;
    -moz-background-size: stretch;
    -o-background-size: stretch;
    // background-size: cover;
    .timeline-header{
        margin-top: 2rem;
        color: #313131;
        font-family: "Libre Baskerville";
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        /* Text style for "The SBS Di" */
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
    }
    .timeline-text{
        margin-top: 2rem;
        color: #292929;
        font-family: Metropolis;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        /* Text style for "We are com" */
        font-style: normal;
        letter-spacing: normal;
        line-height: 30px;
    }
    .icons-wrap{
        padding-top: 2rem;
        display: flex;
        height:20rem;
        .icon{
            width: 82px;
            height: 112px;
        }
        h6{
            font-family: "Libre Baskerville";
            margin-top: 1rem;
            font-weight: bold;
            line-height: 25px;
        }
        .icon-1{
            align-items: flex-end;
        }
        .icon-2{
            align-items: center;
        }
        .icon-3{
            align-items: flex-start;
        }
        .icon-4{
            align-items: center;
        }
        .icon-5{
            align-items: flex-end;
        }
    }
    .btn{
        color: #ffffff;
        font-family: Metropolis;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #93cb46;
        border:none;
        width: 10rem;
        padding: 1rem;
    }
    .description-wrap,.description-wrap-2{
        padding-top: 6rem;
        // background-color: yellow;
        .description-heading{
            color: #313131;
            font-family: "Libre Baskerville";
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: 61px;
            text-align: left;
        }
        .description-text{
            color: #292929;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.36px;
            line-height: 35px;
            text-align: left;
            /* Text style for "We are com" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        .sub-text{
            // color: #707070;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.27px;
            line-height: 30px;
            // text-align: left;
            /* Text style for "This pole" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        .badge-icon{
            width: 117px;
            height: 120px;
        }
    }
    .description-button{
        padding-top: 2rem;
    }
}
.extra-padding-top{
    padding-top: 6rem !important;
}

/*Media Queries*/
// Small devices (landscape phones, 576px and up)
@media (min-width: 280px) {  
    .timeline-wrap .description-wrap {
        padding-top: 40rem !important;
    }
    .description-wrap-2 .col-sm-2{
        text-align: center;
    }
  }
@media (min-width: 576px) {  
    .icons-wrap{
        height: unset;
    }
    .description-wrap{
        Image{
            display: none;
        }
    }
    .timeline-wrap .description-wrap {
        padding-top: 6rem !important;
    }
    .description-wrap-2 .col-sm-2{
        text-align: left;
    }
    
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
    .timeline-wrap .description-wrap {
        padding-top: 6rem !important;
    }
    .description-wrap-2 .col-sm-2{
        text-align: left;
    }
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .timeline-wrap .description-wrap {
        padding-top: 6rem !important;
    }
    .description-wrap-2 .col-sm-2{
        text-align: left;
    }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .timeline-wrap .description-wrap {
        padding-top: 6rem !important;
    }
    .description-wrap-2 .col-sm-2{
        text-align: left;
    }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .timeline-wrap .description-wrap {
        padding-top: 6rem !important;
    }
    .description-wrap-2 .col-sm-2{
        text-align: left;
    }
  }