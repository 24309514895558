.services-wrap{
    margin-top: 3rem;
    .img-main{
        width: 60%;
        object-fit: contain;
    }
    h3{
        color: #313131;
        font-family: "Libre Baskerville";
        // font-size: 20px;
        font-weight: 700;
        /* Text style for "Our Servic" */
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        padding-bottom: 3rem;
        padding-top: 5rem;
    }
    .services-btn-wrap{
        padding-bottom: 2rem;
        // padding-top: 3rem;
        .services-btn{
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #569ed2;
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            border: none;
            padding: 1rem;
        }
    }
    h6{
        // margin-top: 2rem; 
        color: #313131;
        font-family: "Libre Baskerville";
        font-size: 16px;
        font-weight: 700;
        /* Text style for "Our Servic" */
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        padding-bottom: 5rem;
        padding-top: 5rem;
    }
}