@import "~bootstrap/scss/bootstrap";
.donate-animation {
  animation: small-bounce 2.2s;
}
@keyframes small-bounce {
  0%, 50%, 70%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-15px);} 
  60% {transform: translateY(-5px);} 
} 
html { // To increase specificity
body {
  margin: 0;
  font-family: "New York Extra Large";
}
}
.App{
  height: 100vh;
}

p {
  line-height: 30px;
}

.slider-wrapper {
  position: relative;
}

.carousel.carousel-slider .control-arrow {
  position: absolute;
  top: 40%;
  background-color: #aaaaaa !important;
  height: 50px;
  border-radius: 5px;
}