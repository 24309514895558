.about-container{
    background-image: url('/assets/images/sbs-about-header.png');
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    min-height: 35rem;
    padding-top:30rem;
    .about-us-heading{
        width: 50%;
        // margin-top: -10rem;
        padding-left: 2rem;
        h2{
            color: #ffffff;
            font-family: "Libre Baskerville";
            // font-size: 20px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 40px;
            text-align: left;
            margin-left: 3rem;
        }
        .btn{
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #569ed2;
            border:none;
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            padding-left: 1rem;
            padding-right: 1rem;

        }
    }
}
.about-us-content{
    background-image: linear-gradient(180deg, rgba(255, 237, 248, 0) 0%, rgba(237, 245, 250, 0.07) 13%, #cbdefa 47%, #8bb3f7 71%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    background-position: 50% -35%;
    padding: 5rem;
    .description-wrap, .description-wrap-2{
        padding-left: 5rem;
        h3{
            color: #4d5e7e;
            font-family: "Libre Baskerville";
            // font-size: 20px;
            font-weight: 700;
            text-align: left;
            /* Text style for "About Our" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
        p{
            color: #3d3d3e;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.91px;
            line-height: 30px;
            text-align: left;
        }
    }
    .description-wrap-2, .footer-wrap{
        padding-top: 5rem;
    }
    .footer-wrap{
        .about-footer{
            padding-left: 5rem;
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
        h3{
            color: #ffffff;
            font-family: "Libre Baskerville";
            // font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: 60px;
            text-align: left;
        }
        p{
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: 0.91px;
            line-height: 30px;
            text-align: left;
        }
    }
}
.about-footer{
    background-image: url('/assets/images/SBS-About-us-footer.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}
/*Media Queries*/
// Small devices (landscape phones, 576px and up)
@media (min-width: 280px) {  
    .description-wrap, .description-wrap-2{
        padding-left: 2rem !important;
    }
    .about-us-content .footer-wrap .about-footer {
        padding-left: 3rem;
    }
    .about-container .about-us-heading {
        width: 75%;
        margin-top: -10rem;
        padding-left: 2rem;
    }
 }
@media (min-width: 576px) {  
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
    .description-wrap, .description-wrap-2{
        padding-left: 5rem !important;
    }
    .about-us-content .footer-wrap .about-footer {
        padding-left: 5rem !important;
    }
    .about-container .about-us-heading {
        width: 50%;
        margin-top: -10rem;
        padding-left: 2rem;
    }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
   
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
   
  }
