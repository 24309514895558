
.header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    .header-first{
      // background-color: #e5edfd;      
      height: 3rem;
      align-items: center;
      .header-first-title{
        color: #333333;
        font-family: "Libre Baskerville";
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
      }
      .header-first-child{
        text-align: right;
      }
      .header-last-child{
        text-align: left;
        padding-left: 0.5rem;
        text-decoration: none;
        color: inherit;
      }
    }
  .header-wrap{
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    // background-color: #ffffff;
    background-image: linear-gradient(#9cbcfa, #ffffff);
  }
  .badge-container{
    background-color: #32415d;
    border-bottom-right-radius: 2rem;
    color: #e1c153;
    font-family: p22-underground;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    .text{
      margin-left: 1rem;
      margin-right: 1rem;
      // align-items: center;
      // justify-content: center;
      // display: flex;
      font-size: 18px;
    }
  }
  .me-auto{
    justify-content: flex-end;
    display: flex;
    flex: 1;
  }
  .navbar-brand{
    color: #626b7e !important;
    font-size: 1.5rem !important;
    font-style: normal;
    letter-spacing: normal;
    line-height: 28px;
    text-align: left;
    font-family: p22-underground;
    font-weight: 400;
  }
  .nav-link{
    color: #32415d !important;
    font-family: p22-underground;
    font-size: 1.25rem !important;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #32415d;;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }   
  }


.nav-link:hover {
  &::after {
    visibility: visible;
    width: 100%;
  } 
}

  .info-container{
    background-color: #32415d;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
    color: #e1c153;
    font-family: p22-underground;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    margin-left: 6rem;
    cursor: pointer;
    .text{
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 18px;
    }
    .info-text{
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 18px;
      color: #fbfbfb;
      font-family: p22-underground;
      font-size: calc(0.5em + 1vw);
      font-weight: 400;
      font-style: normal;
      letter-spacing: 2.5px;
      line-height: normal;
      text-align: left;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      .info-text-main{
          color: #e9c655;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-transform: uppercase;
          padding-right: 5px;
      }
      .info-text-sub{
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-transform: uppercase;
      }
    }
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 280px) {  
  .header .header-first .header-first-child {
    text-align: left;
  }
}
@media (min-width: 576px) {  
  .header .header-first .header-first-child {
    text-align: left;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 100% !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .header .header-first .header-first-child {
    text-align: left;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 100% !important;
}
 }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .col-sm-5 {
    flex: 0 0 auto;
    width: 60.66667% !important;
  }
 }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  .header .header-first .header-first-child {
    text-align: right;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667% !important;
  }
 }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  
  .header .header-first .header-first-child {
    text-align: right;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667% !important;
}
}
.home-btn{
  cursor: pointer;
}
.underline{
  border-bottom-width: 2px;
}