.contact-us-wrap{
    padding-bottom: 2rem;
    padding-top: 5rem;
    .input-label{
        color: #707070;
        font-family: Metropolis;
        font-size: 16px;
        text-align: left;
        /* Text style for "First Name" */
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
    }
    h3{
        color: #313131;
        font-family: "Libre Baskerville";
        // font-size: 20px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        padding-bottom: 1.5rem;
        text-align: left;
    }
    .enquiry-wrapper{
        width:45%;
        margin-left: 0 !important;                
        margin-bottom: 1rem;

        .col:first-child {
            background-color: #dddddd;
            border-top-left-radius: 45px;
            border-bottom-left-radius: 45px;
            border: 1px solid #dddddd;
            cursor: pointer;
            padding: 0.5rem;
        }

        .col:last-child {
            background-color: #dddddd;
            border-top-right-radius: 45px;
            border-bottom-right-radius: 45px;
            border: 1px solid #dddddd;
            border-left-width: 1px;
            border-left-style: groove;
            cursor: pointer;
            padding: 0.5rem;
        }

        .col.active {
            background-color: #569ed2;
            
            .enquiry-text {
                color: #ffffff;
            }
        }

        .enquiry-text{
            color: #707070;
            font-family: "Helvetica Neue";
            font-size: 14px;
            text-align: left;
            /* Text style for "Message" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
    }
    .submit-btn-wrap{
        text-align: left !important;
        padding-left: 0 !important;
        .submit-btn{
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            background-color: #96d63f;
            border: none;
            color: #ffffff;
            font-family: Metropolis;
            font-size: 16px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: center;
            border: none;
            width: 10rem;
            padding: 1rem;
            :hover{
                background-color: #96d63f !important; 
                border: none !important;
            }
        }
    }
}