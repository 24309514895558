
.home-container{
    width: 100%;
    // height: 100vh;
    padding-top:12rem;
    .carousel{
        background-image: url('/assets/images/Rectangle_49 (1).png');
        background-repeat: no-repeat;
        background-position: center;
    }
    .info-container{
        height: 3rem;
        background-color: #32415d;
        align-items: center;
        text-align: center;
        cursor: pointer;
        .img-main {
            vertical-align: inherit;
        }
        .info-text{
            color: #fbfbfb;
            font-family: p22-underground;
            font-size: calc(0.5em + 1vw);            
            font-style: normal;            
            line-height: normal;
            text-align: left;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            .info-text-main{
                color: #e9c655;
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                text-transform: uppercase;
                padding-right: 10px;
                font-size: 30px;
                letter-spacing: 2.5px;
            }
            .info-text-sub{
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                text-transform: uppercase;
                font-size: 30px;
                letter-spacing: 2.5px;
            }
        }
    }
}
.section-2{
    background-image: url('/assets/images/Rectangle_47.png');
    // background-image: linear-gradient(180deg, rgba(255, 237, 248, 0) 0%, rgba(237, 245, 250, 0.07) 13%, rgba(143, 179, 250, 0.95) 63%, rgba(255, 255, 255, 0) 100%);
    background-repeat: no-repeat;
    background-position: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-size:cover;

}