.policy-container {
    h1 {
        margin-top: 5rem;
    }
    h2 {
        text-decoration: underline;
        margin-top: 2rem;
    }
    h3 {
        margin-top: 2rem;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1rem;
    }
    h1, h2, h3, h4, h5, h6, p, li, strong {
        font-family: "Libre Baskerville";
    }
    li {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}