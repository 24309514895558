.services-container{
    margin-top: 12rem;
    h3{
        color: #4d5e7e;
        font-family: "Libre Baskerville";
        // font-size: 20px;
        font-weight: 700;
        text-align: left;
        /* Text style for "Our Servic" */
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
    }
    p{
        color: #393f40;
        font-family: p22-underground;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        /* Text style for "We are com" */
        font-style: normal;
        letter-spacing: normal;
        line-height: 30px;
        padding-top: 1rem;
    }
    .image-wrap{
        text-align: right;
        .image{
            width: 80%;
        }
    }
    .services-content{
        margin-top: 5rem;
        h3{
            color: #4d5e7e;            
            font-family: "Libre Baskerville";
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: 38px;
            text-align: left;
            /* Text style for "Full excav" */
            font-style: normal;
            letter-spacing: normal;
            line-height: normal
        }
        p{
            color: #292929;
            font-family: p22-underground;
            font-size: 18px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.36px;
            line-height: 35px;
            text-align: left;
            /* Text style for "Our team f" */
            font-style: normal;
            letter-spacing: normal;
            line-height: 30px;
        }
        .list{
            // background-color: #e3eff5;
            .list-group{
                padding-top: 1rem;
                padding-bottom: 1rem;
                background-color: #e3eff5;
                cursor: pointer;
                border: none;
                .link {
                    color: #313131;
                    font-family: "Libre Baskerville";
                    font-size: 16px;
                    text-align: left;
                    /* Text style for "Ground wir" */
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-decoration: none;
                    font-weight: bold;
                }
            }
            .list-group-item.active {
                z-index: 2;
                background-color: #ffffff;
                border: none;
            }
        }
        .service-content-wrap{
            margin-bottom: 4rem;
            background-image: url('/assets/images/Path_45 (1).png');
            height: 100%;
            background-position: bottom;
            background-repeat: no-repeat;            
        }
    }
}