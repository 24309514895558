.location-container{
    background-image: url('/assets/images/Rectangle_47.png');
    // background-image: linear-gradient(180deg, rgba(255, 237, 248, 0) 0%, rgba(237, 245, 250, 0.07) 13%, rgba(143, 179, 250, 0.95) 63%, rgba(255, 255, 255, 0) 100%);
    background-repeat: no-repeat;
    background-position: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-size:cover;
    margin-top: 6rem;

    // background-image: url('/assets/images/sbs-about-header.png');
    .location-heading{
        padding-top: 6rem;
        padding-left: 5rem;
        h3{
            /* Style for "Below is a" */
        color: #606060;
        font-family: "Libre Baskerville";
        // font-size: 20px;
        font-weight: 700;
        // font-style: normal;
        // letter-spacing: normal;
        // line-height: 60px;
        text-align: left;
        /* Text style for "Below is a" */
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        margin-bottom: 1.5rem;
        }
        p{
            color: #606060;
            font-family: p22-underground;
            font-size: 18px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            text-align: left;
            /* Text style for "However, i" */
            font-style: normal;
            letter-spacing: normal;
            line-height: 30px;
        }
    }
    .location-img{
        width:50%
    }
    .info-wrap{
        margin-top: 2rem;
        padding-top: 1rem;
        background-color: #32415d;
        align-items: center;
        justify-content: center;
        .arrow-bottom{
            cursor: pointer;
        }
        span{
            text-align: center;
            span{
                color: #ffffff;
                font-family: "Libre Baskerville";
                font-size: 20px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: normal;
                line-height: 30px;
                text-align: center;
            }
        }
    }
    .contact-us-wrap {
        padding-bottom: 2rem;
        padding-top: 4rem;
    }
}